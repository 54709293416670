<template>
  <div>
    <h1>
      Report: {{ project.title }}
    </h1>
    <h2 class="text-subtitle-1 mb-4">
      {{ project.capacity }} • {{ project.type }} • {{ project.region }}, {{ project.country }}
    </h2>
    <v-row>
      <v-col cols="4">
        <v-card outlined min-height="300px">
          <v-card-title>
            Overview
          </v-card-title>
          <p class="metric px-4 py-3 ma-0">
            <span class="label">Cost:</span>
            <span>{{ project.cost | toCurrency }}</span>
          </p>
          <p class="metric px-4 py-3 ma-0">
            <span class="label">Lifetime:</span>
            <span>{{ lifetime }} years</span>
          </p>
          <p class="metric px-4 py-3 ma-0">
            <span class="label">Operating time:</span>
            <span>{{ operating_days | toNumber }} days</span>
          </p>
          <p class="metric px-4 py-3 ma-0">
            <span class="label">Revenue generated:</span>
            <span>{{ projectData.cumulativeRevenueSeries[0].data.at(-1)[1] | toCurrency }}</span>
          </p>
          <p class="metric px-4 py-3 ma-0">
            <span class="label">IRR:</span>
            <span>{{ project.IRR | toNumber }}%</span>
          </p>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card outlined min-height="300px">
          <highcharts :options="periodicRevenueChartOptions" style="height: 300px"></highcharts>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card outlined min-height="300px">
          <highcharts :options="revenueSplitChartOptions" style="height: 300px"></highcharts>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card outlined min-height="300px">
          <highcharts :options="cumulativeRevenueChartOptions" style="height: 300px"></highcharts>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      project: {},
      projectData: {},
      revenueSplitChartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          events: {
            render: function() {
              this.reflow()
            },
          },
        },
        title: {
          text: 'Revenue Split',
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.2f}%',
            },
            showInLegend: true,
          },
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          itemMarginTop: 15,
          itemStyle: {
            color: '#ffffffde',
          },
          itemHoverStyle: {
            color: '#ffffff',
          },
        },
        colors: [
          colors.blue.lighten2,
          colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [],
      },
      periodicRevenueChartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
          events: {
            render: function() {
              this.reflow()
            },
          },
        },
        title: {
          text: 'Revenue',
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>${point.y:,.0f}<br/>Total: ${point.stackTotal:,.0f}</b>',
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          title: {
            text: 'Date',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Amount ($)',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            shadow: false,
            stacking: 'normal',
          },
        },
        legend: {
          enabled: false,
        },
        colors: [
          colors.blue.lighten2,
          colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [],
      },
      cumulativeRevenueChartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
          events: {
            render: function() {
              this.reflow()
            },
          },
        },
        title: {
          text: 'Cumulative Revenue',
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>${point.y:,.0f}<br/>Total: ${point.stackTotal:,.0f}</b>',
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          title: {
            text: 'Date',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        yAxis: {
          min: 0,
          minRange: null,
          title: {
            text: 'Total ($)',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
          plotLines: [
            {
              value: null,
              color: 'red',
              dashStyle: 'shortdash',
              width: 2,
              label: {
                text: 'Cost',
                style: {
                  color: '#ffffffde',
                },
              },
            },
          ],
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            shadow: false,
            stacking: 'normal',
          },
        },
        legend: {
          enabled: false,
        },
        colors: [
          colors.blue.lighten2,
          colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [],
      },
    }
  },
  computed: {
    operating_days() {
      return (Date.now() - this.project.connectionDate) / 86400000
    },
    lifetime() {
      return this.project.lifetime
    },
  },
  mounted() {
    this.project = this.$store.getters.getProject(this.$route.params.slug)
    this.projectData = this.$store.getters.getProjectData(this.$route.params.slug)

    this.revenueSplitChartOptions.series = this.projectData.revenueSplitSeries
    this.periodicRevenueChartOptions.series = this.projectData.periodicRevenueSeries
    this.cumulativeRevenueChartOptions.series = this.projectData.cumulativeRevenueSeries

    this.cumulativeRevenueChartOptions.yAxis.minRange = this.project.cost
    this.cumulativeRevenueChartOptions.yAxis.plotLines[0].value = this.project.cost
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
}
.theme--dark .metric {
  border-color: #ffffff12;
}
.theme--light .metric {
  border-color: #12385012;
}
.label {
  width: 150px;
  display: inline-block;
}
</style>
